<template>

    <header>
      <div style="position: relative; display: unset">
        <title>Postzegels</title>

        <div class="topnav" id="myTopnav">
          <div class="links">
            <router-link to="/">
              <img
                class="logo"
                src="@/assets/logo.png"
                alt="Postzegels"
                title="Postzegels Nederland logo"
            /></router-link>
            <router-link class="menu-item" :to="{ name: 'Home' }"
              >HOME</router-link
            >
            <router-link class="menu-item" :to="{ name: 'Partijen' }"
              >PARTIJEN</router-link
            >
            <router-link class="menu-item" :to="{ name: 'LosseNummers' }"
              >LOSSE NUMMERS</router-link
            >
            <router-link class="menu-item" :to="{ name: 'Inkoop' }"
              >INKOOP</router-link
            >
            <router-link class="menu-item" :to="{ name: 'Info' }"
              >INFO</router-link
            >
            <router-link class="menu-item" :to="{ name: 'Info' }">
              <v-icon>mdi-cart</v-icon>
            </router-link>

            <div id="header_right">
              <div id="contact">
                <div style="font-size:15px; font-weight:bold;">
                  Postzegel Partijen Centrale
                </div>
                Piet Heinstraat 112 - 2518 CM, Den Haag<br />
                Tel: 070 - 362 52 63<br />
                E-mail:
         
                <a  href="mailto:admin@postzegels.com" class="red-link">admin@postzegels.com</a
                ><br />
                <b>Openingstijden: ma t/m za 9:00 - 16.00 uur</b>
              </div>
              <div id="flags_logos">
                <div id="logos_right">
                  <img
                    id="logo1"
                    src="@/assets/home/nvph_logo_small.png"
                    alt=""
                  />
                  <img id="logo2" src="@/assets/home/pts_logo_1.png" alt="" />
                </div>
                 <!--  <div id="flags">
                  <a href="http://www.postzegels.com">
                    <img
                      src="@/assets/home/flag-netherlands.gif"
                      alt="Postzegels.com"
                    />
                  </a>
             <a href="http://www.stampcollectioncenter.com">
                    <img
                      src="@/assets/home/flag-usa.gif"
                      alt="StampCollectionCenter"
                    />
                  </a>
                </div>-->  
              </div>
            </div>
          </div>

          <div id="hamburger" @click="openSidebar()">
            <div @click="openSidebar()"></div>
            <div @click="openSidebar()"></div>
            <div @click="openSidebar()"></div>
          </div>
        </div>
        <div id="sidebar">
          <div id="cross" @click="closeSidebar()">
            <span>&times;</span>
          </div>
          <router-link class="menu-item" :to="{ name: 'Home' }"
            >HOME</router-link
          >
          <router-link class="menu-item" :to="{ name: 'Partijen' }"
            >PARTIJEN</router-link
          >
          <router-link class="menu-item" :to="{ name: 'LosseNummers' }"
            >LOSSE NUMMERS</router-link
          >
          <router-link class="menu-item" :to="{ name: 'Inkoop' }"
            >INKOOP</router-link
          >
          <router-link class="menu-item" :to="{ name: 'Info' }"
            >INFO</router-link
          >
          <router-link class="menu-item" :to="{ name: 'Info' }">
            <v-icon>mdi-cart</v-icon>
          </router-link>
        </div>
      </div>
    </header>

</template>
<script>
export default {
  data() {
    return {};
  },

  mounted() {
    document
      .querySelectorAll("a")
      .forEach((link) => link.addEventListener("click", this.closeSidebar));
  },
  methods: {
    openSidebar() {
      document.getElementById("sidebar").style.display = "flex";
    },
    closeSidebar() {
      document.getElementById("sidebar").style.display = "none";
    },
  },
};
</script>

<style scoped>
#logo1 {
  width: 40px;
}
#logo2 {
  width: 40px;
}

#flags {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  
}
#logos_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
}
#flags_logos {
  margin-left: 10px;
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
#contact {
  padding-top: 20px;
  width: 75%;
  line-height: 1.65;
}
.links {
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* This will center them vertically */
  padding: 0 10px; /* Adjust the padding as needed */
  flex-grow: 1;
}

img {
  height: 35px;
  width: 35px;
}
#cross {
  width: 100%;
  margin: 10px 0px;
  cursor: pointer;
  position: relative;
}
#cross span {
  font-size: 40px;
  color: green;
  float: right;
  margin-right: 5%;
}
#sidebar {
  position: fixed;
  top: 0px;
  right: 0%;
  display: none;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: #375a9e;
  z-index: 1000;
}
#hamburger {
  display: none;
  margin-right: 5%;
  cursor: pointer;
}
#hamburger div {
  width: 30px;
  height: 10px;
  margin-top: 5px;
  border-radius: 5px;
  background: black;
}
#hamburger div:first-child {
  margin-top: 0px;
}
.topnav {
  background: #375a9e;
  display: flex;
  position: sticky;
  font-size: 100;
  font-weight: 100;
  border-bottom: 5px solid #bdc4c7;
  font-family: "open_sanssemibold";
  width: 100%;
  z-index: 100;
  align-items: center;
  padding: 0 20px;
}
.logo {
  max-width: none; /* or set to the original width of your logo */
  height: auto; /* keeps the aspect ratio intact */
  margin-right: auto; /* if you want to push everything else to the right */
  width: 40%; /* or adjust to your preference */
  min-width: 100px; /* or the smallest width you want your logo to have */
}

.menu-item v-icon {
  width: 35px; /* or your preferred size */
  height: auto;
  /* Add any other styles for positioning here */
}

.menu-item {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.menu-item:hover {
  background-color: white;
  opacity: 0.2;
  color: orange;
}
.menu-item a:active {
  background-color: orange !important;
  color: orange;
}
.menu-item .active {
  background-color: orange !important;
}
.menu-item.router-link-exact-active.router-link-active {
  color: orange;
}
.topnav .icon {
  display: none;
}
@media screen and (max-width: 768px) {
  #hamburger {
    display: block;
  }
  .links {
    display: none;
  }
  #sidebar {
    display: none;
  }
}
#header_right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.red-link {
            color: white; 
            font-weight: bold;/* Rood maken van de tekstkleur */
        }
</style>
